<template>
  <agreement :callback="setInfoAgreement" />
</template>

<script>
import { mapActions } from 'vuex'
import Agreement from '@/components/Payments/Agreement'

/**
 * Agreement to retain payment method info when customer is adds/edits a payment method.
 * Intended as a reusable smart component to manage the state of the 'paymentMethods/infoAgreement' boolean flag.
 */
export default {
  name: 'PaymentMethodInfoAgreement',
  components: { Agreement },
  data() {
    return {}
  },
  beforeDestroy() {
    this.setInfoAgreement(false)
  },
  mounted() {
    this.setInfoAgreement(false)
  },
  methods: {
    ...mapActions('paymentMethods', ['setInfoAgreement']),
  },
}
</script>
