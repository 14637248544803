<template>
  <div class="agreement-container">
    <b-row>
      <validation-provider
        ref="validationProvider"
        v-slot="{ errors }"
        rules="required|agreement-indicator"
        name="Agreement"
      >
        <b-checkbox
          id="agreement-indicator-input"
          v-model="agreementAccepted"
          class="agreement-text"
          @input="callback(agreementAccepted)"
        >
          <span class="mt-4 mb-2">
            I accept the
            <a :href="tosUrl" target="_blank">
              Terms and Conditions
            </a>
          </span>
        </b-checkbox>
        <b-form-invalid-feedback :state="!errors[0] ? null: false">
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </b-row>
  </div>
</template>

<script>

import { extend } from 'vee-validate'
import { mapGetters } from 'vuex'

extend('agreement-indicator', {
  validate(value) {
    return value === true
  },
  message: 'You must agree to the terms and conditions',
})
/**
 * Presentation component for various Terms of Service checkbox payment agreements.
 * Intended as a base-layer of wrapper smart components that manage different payment agreements.
 */
export default {
  name: 'Agreement',
  props: {
    callback: {
      type: Function,
      default: () => { return true },
    },
  },
  data() {
    return {
      agreementAccepted: false,
      tosUrl: null,
    }
  },
  computed: {
    ...mapGetters('website', ['newTosUrl']),
  },
  mounted() {
    this.tosUrl = this.newTosUrl
  },
}
</script>

<style scoped lang="scss">

  .agreement-container {

    .invalid-feedback {
      text-align: center !important;
    }
  }
</style>
