var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "agreement-container" },
    [
      _c(
        "b-row",
        [
          _c("validation-provider", {
            ref: "validationProvider",
            attrs: { rules: "required|agreement-indicator", name: "Agreement" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "b-checkbox",
                      {
                        staticClass: "agreement-text",
                        attrs: { id: "agreement-indicator-input" },
                        on: {
                          input: function ($event) {
                            return _vm.callback(_vm.agreementAccepted)
                          },
                        },
                        model: {
                          value: _vm.agreementAccepted,
                          callback: function ($$v) {
                            _vm.agreementAccepted = $$v
                          },
                          expression: "agreementAccepted",
                        },
                      },
                      [
                        _c("span", { staticClass: "mt-4 mb-2" }, [
                          _vm._v("\n          I accept the\n          "),
                          _c(
                            "a",
                            { attrs: { href: _vm.tosUrl, target: "_blank" } },
                            [
                              _vm._v(
                                "\n            Terms and Conditions\n          "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "b-form-invalid-feedback",
                      { attrs: { state: !errors[0] ? null : false } },
                      [_vm._v("\n        " + _vm._s(errors[0]) + "\n      ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }