<template>
  <div class="label-container">
    <div class="label-icon-container">
      <b-img aria-label="Warning message" src="/images/payments/message-warning-triangle.svg" />
    </div>
    <div class="label-text-container">
      <span class="label-text">
        {{ message }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WarningBanner',
  props: {
    message: String,
  },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">

.label-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: #FFF;
  border: 1px solid #FFC107;
  border-radius: 0.25rem;
  width: 100%;
  margin: 0 0 1rem 0;

  .label-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFC107;
    padding: 0.5rem;
    height: 100%;

    img {
      height: 1rem;
      width: 1rem;
    }
  }

  .label-text-container {
    display: flex;
    align-items: center;
    width: 100%;

    .label-text {
      padding-left: 0.5rem;
      color: #363636;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>

