var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "payment-method-duplicate-card-modal",
      attrs: {
        id: "payment-method-duplicate-card-modal",
        "no-close-on-backdrop": true,
        size: "md",
        "hide-footer": "",
        title: "Whoops",
        "header-class": "no-borders",
      },
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("p", [
        _vm._v(
          "\n    It looks like we already have this payment method on file.\n    Would you like to update the details of your current payment method?\n  "
        ),
      ]),
      _c(
        "b-button",
        {
          attrs: { variant: "danger", "aria-label": "cancel button" },
          on: { click: _vm.cancelAddPaymentMethod },
        },
        [_vm._v("\n    Cancel\n  ")]
      ),
      _c(
        "b-button",
        {
          staticClass: "float-right",
          attrs: { variant: "primary", "aria-label": "yes button" },
          on: { click: _vm.updatePreviousPaymentMethod },
        },
        [_vm._v("\n    Yes\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }