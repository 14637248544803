var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "label-container" }, [
    _c(
      "div",
      { staticClass: "label-icon-container" },
      [
        _c("b-img", {
          attrs: {
            "aria-label": "Warning message",
            src: "/images/payments/message-warning-triangle.svg",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "label-text-container" }, [
      _c("span", { staticClass: "label-text" }, [
        _vm._v("\n      " + _vm._s(_vm.message) + "\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }