<template>
  <b-modal
    id="payment-method-duplicate-card-modal"
    ref="payment-method-duplicate-card-modal"
    :no-close-on-backdrop="true"
    size="md"
    hide-footer
    title="Whoops"
    header-class="no-borders"
    @close="$emit('close')"
  >
    <p>
      It looks like we already have this payment method on file.
      Would you like to update the details of your current payment method?
    </p>
    <b-button
      variant="danger"
      aria-label="cancel button"
      @click="cancelAddPaymentMethod"
    >
      Cancel
    </b-button>
    <b-button
      variant="primary"
      class="float-right"
      aria-label="yes button"
      @click="updatePreviousPaymentMethod"
    >
      Yes
    </b-button>
  </b-modal>
</template>

<script>
export default {
  name: "DuplicatePaymentMethodModal",
  methods: {
    cancelAddPaymentMethod() {
      this.$emit('cancelAddPaymentMethod')
      this.$bvModal.hide('payment-method-duplicate-card-modal')
    },
    updatePreviousPaymentMethod() {
      this.$emit('updatePreviousPaymentMethod')
      this.$bvModal.hide('payment-method-duplicate-card-modal')
    },
  },
}
</script>

<style lang="scss">
#payment-method-duplicate-card-modal {
  .modal-content {
    border-radius: 0.5rem;
  }
  .modal-header {
    align-items: center;
  }
  .modal-title {
    font-size: 1.75em;
  }
}
</style>
